<template>
  <div>
    <Card title="画面/视频/音频">
      <!-- <Row
        v-if="disabled"
        class="p-b-5"
      >
        <Progress :percent="percentage"></Progress>
      </Row> -->
      <Upload
        ref="upload"
        :show-upload-list="false"
        :before-upload="handleBeforeUpload"
        :on-progress="handleProgress"
        :on-success="handleSuccess"
        :headers="headers"
        :data="{ category: 1, orderId: orderId }"
        :format="[
          'jpg',
          'jpeg',
          'png',
          'mp4',
          'wmv',
          'mov',
          'avi',
          'mpeg',
          'mp3',
          'wav',
        ]"
        :action="''"
        :disabled="uploading"
        multiple
        type="drag"
      >
        <div style="padding: 20px 0">
          <Icon
            type="ios-cloud-upload"
            size="52"
            :style="uploading ? 'color:#ccc' : 'color: #3399ff'"
          ></Icon>
          <p>
            {{
              uploading
                ? "请耐心等待本次上传结束"
                : "点击或拖拽文件到这里进行上传，只支持图片、视频和音频类型"
            }}
          </p>
        </div>
      </Upload>
      <div class="file-list-box">
        <div
          class="demo-upload-list"
          v-for="(file, index) in materialFileList"
          :key="'existFile_' + index"
        >
          <img
            v-if="file.mimeType.indexOf('image/') > -1"
            :src="file.fileKey"
          />
          <a
            v-else-if="file.mimeType.indexOf('video/') > -1"
            style="word-wrap: break-word; width: 100%; height: 60px"
            >{{ file.fileName }}</a
          >
          <a
            v-else-if="file.mimeType.indexOf('audio/') > -1"
            style="word-wrap: break-word; width: 100%; height: 60px"
            >{{ file.fileName }}</a
          >
          <div class="demo-upload-list-cover">
            <template v-if="file.mimeType.indexOf('audio/') > -1">
              <Icon
                type="ios-pause"
                v-if="file.id === curFileId"
                @click.native="handlePuseVoice"
              />
              <Icon
                type="md-volume-down"
                v-else
                @click.native="handleView(file)"
              />
            </template>
            <template v-else>
              <Icon
                type="ios-eye-outline"
                @click.native="handleView(file)"
              ></Icon>
            </template>
            <Icon
              type="ios-trash-outline"
              @click.native="handleRemove(file)"
            ></Icon>
          </div>
        </div>
        <div
          class="demo-upload-list"
          v-for="(item, index) in uploadList"
          :key="'ufile_' + index"
        >
          <template v-if="item.status === 'finished'">
            <img
              v-if="item.data.mimeType.indexOf('image/') > -1"
              :src="item.data.fileKey"
            />
            <a
              v-else-if="item.data.mimeType.indexOf('video/') > -1"
              style="word-wrap: break-word; width: 100%; height: 60px"
              >{{ item.data.fileName }}</a
            >
            <a
              v-else-if="item.data.mimeType.indexOf('audio/') > -1"
              style="word-wrap: break-word; width: 100%; height: 60px"
              >{{ item.data.fileName }}</a
            >
            <div class="demo-upload-list-cover">
              <template v-if="item.data.mimeType.indexOf('audio/') > -1">
                <Icon
                  type="ios-pause"
                  v-if="item.data.id === curFileId"
                  @click.native="handlePuseVoice"
                />
                <Icon
                  type="md-volume-down"
                  v-else
                  @click.native="handleView(item.data)"
                />
              </template>
              <template v-else>
                <Icon
                  type="ios-eye-outline"
                  @click.native="handleView(item.data)"
                ></Icon>
              </template>
              <Icon
                type="ios-trash-outline"
                @click.native="handleRemove(item.data, 0)"
              ></Icon>
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info
            ></Progress>
          </template>
        </div>
      </div>
    </Card>

    <Card class="m-t-10" title="资质材料">
      <Upload
        multiple
        type="drag"
        :headers="headers"
        :data="{ category: 2, orderId: orderId }"
        :before-upload="handleBeforeUploadFile"
        :on-success="handleFileSuccess"
        :action="uploadUrl"
        :show-upload-list="showUploadFileStatus"
        :format="['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx']"
      >
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>点击或拖拽文件到这里进行上传</p>
        </div>
      </Upload>
      <Row v-show="fileList.length">
        <i-col span="16"><b>文件名称</b></i-col>
        <i-col span="6"><b>文件大小</b></i-col>
        <i-col span="2"><b>操作</b></i-col>
        <hr />
      </Row>

      <Row
        v-for="(file, fileIndex) in fileList"
        :key="fileIndex"
        v-show="fileList.length"
      >
        <i-col span="16">{{ file.fileName }}</i-col>
        <i-col span="6">{{ file.length }} KB</i-col>
        <i-col span="2"
          ><a class="delete" @click="handleRemove(file)">删除</a></i-col
        >
        <i-col span="24">
          <hr />
        </i-col>
      </Row>
    </Card>

    <div class="m-t-10">
      <Card>
        <p slot="title" style="color: #fff">文件上传要求</p>
        <p style="color: #fa8b59">
          1、图片、视频和音频单个文件大小不超过100M。单次上传结束后，可再次进行选择上传。
        </p>
        <p style="color: #fa8b59">2、图片上传格式为：.jpg .png .jpeg .img。</p>
        <p style="color: #fa8b59">
          3、视频上传格式为：.mp4 .wmv .mov .avi .mpeg。
        </p>
        <p style="color: #fa8b59">4、音频上传格式为：.mp3 .wav。</p>
        <p style="color: #fa8b59">
          5、资质文件上传格式为：.jpg .png .jpeg .pdf .doc .docx .xls .xlsx。
        </p>
      </Card>
    </div>
    <div class="text-right m-t-10">
      <Button
        type="success"
        size="small"
        v-if="!isEdit"
        @click="confirmUpload"
        :disabled="isUploadFiles"
        >确认提交</Button
      >
    </div>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal
      title="画面预览"
      width="800"
      v-model="visibleImage"
      :footer-hide="true"
    >
      <img :src="ImageUrl" v-if="visibleImage" style="width: 100%" />
    </Modal>
    <Modal
      title="视频预览"
      width="800"
      v-model="visibleVideo"
      :footer-hide="true"
    >
      <video :src="VideoUrl" controls="controls" style="width: 100%">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->

    <Modal v-model="showConfirmUpload" width="400">
      <p slot="header" class="text-center">
        <span>上传提示</span>
      </p>
      <p><strong>请注意，上传文件后将立即提交管理审批！</strong></p>
      <p class="p-t-10">请选择审批通道：</p>
      <RadioGroup v-model="sub">
        <Radio
          v-for="(item, i) in subs"
          :key="i"
          :label="item.subProcessType"
          >{{ item.subProcessTypeName }}</Radio
        >
      </RadioGroup>
      <p class="p-t-10">上传备注信息：</p>
      <p>
        <i-input
          maxlength="500"
          v-model="uploadDesc"
          show-word-limit
          type="textarea"
          :rows="4"
          placeholder="请输入上传备注信息，非必填..."
          style="width: 320px"
        />
      </p>
      <div slot="footer">
        <Button
          type="primary"
          size="large"
          long
          :loading="modal_loading"
          @click="handleUpload"
          >开始上传</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { getStorage } from '@/utils/storage'
import {
  listDraftFile,
  deleteFile,
  submit,
  getOrderProcessType
} from '@/api/order/orderfileV2'
import { mergeAndCompress } from '@/api/order/orderfile'
import { getSubProcessList } from '@/api/approval/processconfig'

export default {
  props: {
    relatedId: {
      type: [Number, String],
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderId: this.$store.state.installSetting.demandOrderId,
      disabled: false,
      modal_loading: false,
      // uploadFieldName: 'imgFiles', // 上传文件字段名
      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-order/v2/orderfile/uploadfile`,
      chunkUploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-order/v1/orderfile/uploadChunk`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      // params: {
      //   orderId: this.orderId
      // },
      maxSize: 100 * 1024,
      tempFileSize: 0, // 临时存储单次上传文件大小
      uploading: false, // 文件上传状态
      uploadList: [],
      materialFileList: [],
      // ImageList: [],
      // VideoList: [],
      // voiceList: [],
      fileList: [],
      showUploadFileStatus: true, // 显示资质文件上传状态
      showConfirmUpload: false,
      visibleImage: false,
      ImageUrl: '',
      visibleVideo: false,
      VideoUrl: '',
      audioFile: null,
      // voicePlayIndex: -1,
      showImgPercentage: false, // 图片，视频进度显示
      percentage: 0, // 图片，视频上传进度

      subs: [],
      sub: '',
      uploadDesc: '', // 上传备注
      curFileId: null
    }
  },
  mounted () {
    if (!this.relatedId || this.relatedId === '') {
      this.getOrderDraftFile()
    }

    // this.uploadList = this.$refs.upload.fileList
  },
  methods: {
    getOrderDraftFile () {
      this.materialFileList = []
      this.fileList = []
      listDraftFile({ orderId: this.orderId }).then((res) => {
        if (res && res.success) {
          this.materialFileList = res.data.length
            ? res.data.filter((x) => [1, 3, 4].includes(x.type))
            : []
          this.fileList = res.data.length
            ? res.data.filter((x) => x.type === 2)
            : []
        }
      })
    },
    handleBeforeUpload (file) {
      if (file.size > 1024 * 1024 * 100) {
        // 单个图片文件不超过100M
        this.$Notice.warning({
          desc: '文件名为“' + file.name + '”的文件上传失败，单个文件不超过100M'
        })
        return false
      }
      this.fileChunk(file)
      return false
      // if (file.type.indexOf('image/') > -1) {
      //   // 获取图片文件的基本信息
      //   const fr = new FileReader()
      //   fr.readAsDataURL(file)
      //   fr.onload = () => {
      //     const image = new Image()
      //     image.src = fr.result
      //     image.onload = () => {
      //       if (image.width > 30000) {
      //         that.$Notice.warning({ desc: '文件过大，单个图片文件像素宽度不超过30000' })
      //         return false
      //       }
      //       // that.ImageList.push(file)
      //     }
      //   }
      // }
      //  if (file.type.indexOf('video/') > -1) {
      //   if (file.size > 73400320) { // 单个视频文件不超过70M
      //     that.$Notice.warning({ desc: '文件过大，单个视频文件最大限制70M' })
      //     return false
      //   }
      //   // that.VideoList.push(file)
      // } else if (file.type.indexOf('audio/') > -1) {
      //   if (file.size > 50 * 1204 * 1024) { // 单个音频文件不超过50M
      //     that.$Notice.warning({ desc: '文件过大，单个音频文件最大限制50M' })
      //     return false
      //   }
      //   // that.voiceList.push(file)
      // }
    },
    /**
     * 文件切片上传
     * @param {*} file 文件
     */
    async fileChunk (file) {
      file.status = 'none'
      file.showProgress = true
      file.percentage = 1
      this.uploadList.push(file)

      const chunkSize = 5 * 1024 * 1024 // 切片大小为5M
      const totalChunk = Math.ceil(file.size / chunkSize) // 切片总数
      const key = new Date().getTime()
      let index = 0
      let successCount = 0

      // 模拟进度条
      const st = setInterval(() => {
        let random = 0
        if (totalChunk > 2) {
          if (file.percentage < 60) {
            random = Math.round(Math.random() * 1 + 4)
          } else if (file.percentage < 85) {
            random = Math.round(Math.random() * 1 + 2)
          } else {
            random = 1
          }
        } else {
          random = Math.round(Math.random() * 2 + 8)
        }

        file.percentage =
          file.percentage < 98 ? file.percentage + random : file.percentage
        // 进行深度拷贝，进度条实时改变
        this.uploadList = cloneDeep(this.uploadList)
      }, 1500)

      while (index < totalChunk) {
        const chunk = file.slice(index * chunkSize, (index + 1) * chunkSize)
        const formData = new FormData()
        // formData.append('category', 1)
        formData.append('key', key)
        formData.append('index', index)
        formData.append('file', chunk)
        formData.append('originalFilename', file.name)

        const result = await axios.post(this.chunkUploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        })
        if (result.data && result.data.errcode === 0) {
          successCount++
          // 所有切片上传完成，调用合并方法
          if (successCount === totalChunk) {
            this.mergeFile(key, file, 1, st)
          }
        }
        index++
      }
    },
    mergeFile (key, file, category, st) {
      const postData = {
        orderId: this.orderId,
        originalFilename: file.name,
        category,
        key
      }
      // 调用合并接口
      mergeAndCompress(postData).then((res) => {
        if (res) {
          if (res.status === 2) {
            // 合并成功，设置文件状态为“finished”
            file.status = 'finished'
            file.data = res.orderFileVo
            file.percentage = 100
            file.showProgress = false
            // 清除进度条定时任务
            clearInterval(st)
          } else {
            // 文件合并中
            setTimeout(() => {
              this.mergeFile(key, file, category, st)
            }, 3500)
          }
        }
        this.uploadList = cloneDeep(this.uploadList)
      })
    },
    handleProgress (event, file, fileList) {
      // 开始上传时，清空累加的文件大小
      this.tempFileSize = 0
      this.uploading = true
      this.uploadList = fileList
      event.target.onprogress = (event) => {
        const uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        )
        file.showProgress = true
        file.percentage = uploadPercent
      }
    },
    handleBeforeUploadFile (file) {
      // this.fileList.push(file)
      // return false
      this.showUploadFileStatus = true
    },
    /**
     * 资质文件上传成功
     */
    handleFileSuccess (response, file, fileList) {
      this.fileList.push(response.data)
      this.showUploadFileStatus = false
    },
    /**
     * 图片、视频和音频上传成功
     */
    handleSuccess (response, file, fileList) {
      // 所有文件上传成功后，更改上传状态
      if (fileList.every((x) => x.status === 'finished')) {
        this.uploading = false
      }
      if (!response.data) {
        this.uploadList.splice(
          this.uploadList.findIndex((x) => x.uid === file.uid),
          1
        )
      }
    },
    getbasesrc (file) {
      return window.URL.createObjectURL(file)
    },
    handleView (file) {
      this.curFileId = file.id
      if (file.mimeType.indexOf('image/') > -1) {
        this.ImageUrl = file.fileKey
        this.visibleImage = true
      } else if (file.mimeType.indexOf('video/') > -1) {
        this.VideoUrl = file.fileKey
        this.visibleVideo = true
      } else if (file.mimeType.indexOf('audio/') > -1) {
        if (this.audioFile) {
          this.audioFile.pause()
        }
        // 初始化音频
        this.audioFile = null
        this.audioFile = new Audio()
        this.audioFile.src = file.fileKey
        this.audioFile.play()
        // 监听播放是否结束
        this.audioFile.addEventListener(
          'ended',
          () => {
            this.curFileId = null
          },
          false
        )
      }
    },
    handleRemove (file, fileType) {
      fileType = fileType === 0 ? 0 : file.type
      deleteFile({ fileId: file.id, orderId: this.orderId }).then((res) => {
        if (res && res.success) {
          this.$Notice.success({ desc: '删除成功' })
          this.curFileId = null
          if (fileType === 0) {
            this.uploadList.splice(
              this.uploadList.findIndex((x) => x.data.id === file.id),
              1
            )
          } else if (fileType === 2) {
            this.fileList.splice(this.fileList.indexOf(file), 1)
          } else {
            this.materialFileList.splice(
              this.materialFileList.indexOf(file),
              1
            )
          }
        }
      })
    },
    handlePuseVoice () {
      this.curFileId = null
      this.audioFile.pause()
    },
    confirmUpload (editBean) {
      // 开始上传问题
      if (editBean.approvalId) {
        this.editBean = editBean
        this.uploadDesc = editBean.desc
      } else {
        this.uploadDesc = ''
      }
      this.initOrderProcessType()
      this.showConfirmUpload = true
    },
    // 加载画面审批类型
    initOrderProcessType () {
      getOrderProcessType({ orderId: this.orderId }).then((res) => {
        this.initSubProcessList(res)
      })
    },

    initSubProcessList (processType) {
      getSubProcessList({ processType: processType }).then((data) => {
        if (data && data.length > 0) {
          this.subs = data
          this.sub = this.subs.length ? this.subs[0].subProcessType : ''
        } else {
          this.subs = []
        }
      })
    },
    handleUpload () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交？',
        onOk: () => {
          this.disabled = true
          this.modal_loading = true
          this.showConfirmUpload = false
          const postData = {
            orderId: this.orderId,
            desc: this.uploadDesc,
            subprocessType: this.sub
          }
          if (this.editBean && this.editBean.approvalId) {
            postData.approvalId = this.editBean.approvalId
            // postData.desc = this.editBean.desc
          }

          if (this.relatedId !== '') {
            // 画面补充，传补充任务的ID
            postData.relatedId = this.relatedId
          }
          submit(postData)
            .then((res) => {
              if (res) {
                // this.ImageList = []
                // this.VideoList = []
                this.materialFileList = []
                this.fileList = []
                this.uploadList = []
                // this.voiceList = []
                this.$emit('watchUploadSuccess')
                this.$store.dispatch('getApprovalFileHistory', {
                  orderId: this.orderId
                })
                this.$Notice.success({ desc: '上传成功' })
              }
            })
            .finally(() => {
              this.disabled = false
              this.modal_loading = false
            })
        }
      })
    }
    // handleUpload (editBean) {
    //   const _this = this
    //   _this.percentage = 0
    //   _this.disabled = true
    //   _this.modal_loading = true
    //   _this.showConfirmUpload = false

    //   const param = new FormData()
    //   param.append('orderId', _this.orderId)
    //   _this.ImageList.forEach(function (file, index) {
    //     param.append('imgFiles', file)
    //   })
    //   _this.VideoList.forEach(function (file, index) {
    //     param.append('vedioFiles', file)
    //   })
    //   _this.fileList.forEach(function (file, index) {
    //     param.append('files', file)
    //   })

    //   _this.voiceList.forEach((file) => {
    //     param.append('voiceFiles', file)
    //   })

    //   if (editBean.approvalId) {
    //     param.append('approvalId', editBean.approvalId)
    //     param.append('desc', editBean.desc)
    //     param.append('deleteFileIds', JSON.stringify(editBean.deleteFileIds))
    //   } else if (this.uploadDesc !== '') { // 上传备注
    //     param.append('desc', this.uploadDesc)
    //   }

    //   if (this.relatedId !== '') { // 画面补充，传补充任务的ID
    //     param.append('relatedId', this.relatedId)
    //   }

    //   axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/crateorderfileapproval',
    //     param,
    //     {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         'ooh-auth': getStorage('ooh-token')
    //       },
    //       withCredentials: true,
    //       onUploadProgress (progress) { // 处理上传文件进度条数据
    //         _this.percentage = Math.round(progress.loaded / progress.total * 100) >= 95 ? 95 : Math.round(progress.loaded / progress.total * 100)
    //       }
    //     }
    //   ).then(res => {
    //     if (res && res.data && !res.data.errcode) {
    //       _this.ImageList = []
    //       _this.VideoList = []
    //       _this.fileList = []
    //       _this.voiceList = []
    //       _this.percentage = 100

    //       _this.$emit('watchUploadSuccess')
    //       _this.$store.dispatch('getApprovalFileHistory', { orderId: this.orderId })
    //       _this.$Notice.success({ desc: '上传成功' })
    //       _this.disabled = false
    //       _this.modal_loading = false
    //     } else {
    //       this.$Notice.error({
    //         desc: res.data.errmsg
    //       })
    //       _this.disabled = false
    //       _this.modal_loading = false
    //     }
    //   })
    // }
  },
  computed: {
    isUploadFiles () {
      // const fileCount = this.ImageList.length + this.VideoList.length + this.fileList.length + this.voiceList.length
      const fileCount =
        this.materialFileList.length +
        this.fileList.length +
        this.uploadList.length
      return fileCount === 0 || this.disabled
    }
  }
}
</script>

<style scoped>
.file-list-box {
  display: flex;
  flex-wrap: wrap;
}

.demo-upload-list {
  display: inline-block;
  width: 120px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
  margin-top: 5px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}

.voice-active {
  border: 2px solid #fa8b59;
}
</style>
