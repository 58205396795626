<template>
  <div>
    <Row class=" m-t-10 m-b-20">
      <i-col span="16">
        <h4 class="workplatform-title">订单上刊素材管理（<span class="text-orange">{{ orderInfo.advertiserName
            }}</span>
          <Tag color="#fff"><span style="color:#141414">{{ htmlToSchedule(orderInfo.startDate, orderInfo.endDate)
              }}</span>
          </Tag>）
        </h4>
      </i-col>
      <i-col span="8">
        <!-- <h4 class="workplatform-title">{{ `发布品牌：${qualificationInfo.platformBrandName}` || '' }}</h4> -->
      </i-col>
    </Row>
    <!-- <ad-approval-quali class="m-b-10" :orderId="orderInfo.id"  :sendQualificationFun="getQualificationData" /> -->
    <Tabs v-model="currenttable">
      <TabPane label="新素材上传" name="newScreen">
        <material-upload></material-upload>
      </TabPane>
      <TabPane label="素材提交记录" name="history" v-if="approvalList.length">
        <material-history v-if="approval"></material-history>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import MaterialUpload from './material/MaterialUpload'
import MaterialHistory from './material/MaterialHistory'
import { GetCurrentSchedule } from '@/utils/dateFormat'
// import AdApprovalQuali from '@/components/common/AdApprovalQuali'

export default {
  mixins: [sysMixins],
  components: {
    MaterialUpload,
    MaterialHistory
    // AdApprovalQuali
  },
  data () {
    return {
      qualificationInfo: {}
    }
  },
  computed: {
    orderInfo () {
      return this.$store.state.installSetting.orderInfo
    },
    approvalList () {
      return this.$store.state.installSetting.approvalFileList
    },
    currenttable: {
      get () {
        return this.$store.state.installSetting.materialManageTab
      },
      set (val) {
        this.$store.commit('set_material_manage_tab', val)
      }
    },
    approval () {
      return this.$store.state.installSetting.approvalFile
    }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'installMaterial', value: '素材管理' })
    this.setActivedTagName('installMaterial')
  },
  destroyed () {
    // const bodyTags = this.$store.state.sys.bodyTags
    // this.$store.commit('SET_BODY_TAGS', bodyTags)
    this.currenttable = 'newScreen'
  },
  methods: {
    htmlToSchedule (sDate, eDate) {
      return GetCurrentSchedule(sDate, eDate, false)
    },
    getQualificationData (data) {
      this.qualificationInfo = data
    }
  }

}
</script>
