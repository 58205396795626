import request from '@/utils/requestV2'
const qs = require('qs')

// 为指定流程增加一个审批节点【已测】
export function addApprovalNode (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/addapprovalnode',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 为指定流程批量增加审批节点【已测】
export function addApprovalNodes (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/addapprovalnodes',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除指定审批节点【已测】
export function deleteApprovalNode (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/deleteapprovalnode',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取特定审批节点【已测】
export function getApprovalNode (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/getapprovalnode',
    method: 'post',
    data: qs.stringify(data)
  })
}
//  获取指定流程的节点配置【已测】
export function getApprovalNodeList (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/getapprovalnodelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
//  获取审批流程类型【已测】
export function getProcessTypeList (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/getprocesstypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
//  更新审批节点【已测】
export function updateApprovalNode (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/updateapprovalnode',
    method: 'post',
    data: qs.stringify(data)
  })
}
//  批量更新审批节点
export function updateApprovalNodeList (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/updateapprovalnodelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

//  获取特定类型默认的审批节点配置
export function getDefaultApprovalNodeList (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/getdefaultapprovalnodelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

//  根据审批类型获取拥有的审批通道信息
export function getSubProcessList (data) {
  return request({
    url: '/ooh-approval/v1/processconfig/getSubProcessList',
    method: 'post',
    data: qs.stringify(data)
  })
}
