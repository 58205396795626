import request from '@/utils/requestV2'
const qs = require('qs')

// 完成一个task【已测】
export function completeTask (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/completetask',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批对象的审批记录【已测】
export function getApprovalSchedule (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/getapprovalschedule',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取某一对象的最新审批状态信息【已测】
export function getReviewStatus (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/getreviewstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查看特定审批任务详情【已测】
export function getTask (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/gettask',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 统计审批任务数【已测】
export function getTaskCount (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/gettaskcount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 条件查询审批任务，分页显示【已测】
export function getTaskPage (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/gettaskpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 中止审批流程（用于审批主体对象已作废/删除/无效，不再需要继续审批）【已测】
export function terminateProcessTask (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/terminateprocesstask',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取任务数
export function getTaskCountComplex (data) {
  return request({
    url: '/ooh-approval/v1/approvaltask/gettaskcountcomplex',
    method: 'post',
    data: qs.stringify(data)
  })
}
